import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from "../assets/images/logo.png";
import NoProfilePicture from "../assets/images/NoProfilePicture.svg";
import MexicanFlag from "../assets/images/mexico-flag.png";
import UnitedStatesFlag from "../assets/images/united-states-flag.png";
import BrazilianFlag from "../assets/images/brazil-flag.png";
import CostaRicaFlag from "../assets/images/costa-rica-flag.png";
import UruguayanFlag from "../assets/images/uruguay-flag.png";
import ColombianFlag from "../assets/images/colombia-flag.png";
import SpanishFlag from "../assets/images/espania-flag.png";
import ArgentinaFlag from "../assets/images/argeitina-flag.png";
import ItalianFlag from "../assets/images/italia-flag.png";
import MontenegroFlag from "../assets/images/montenegro-flag.png";

function Profile() {
  const { id } = useParams();
  const [playerInformation, setPlayerInformation] = useState(null);
  const [playerContractInformation, setPlayerContractInformation] = useState(null);

  function setFlag(nationality) {
    var response;
    if (nationality == "Mexican") {
      response = MexicanFlag;
    }
    if (nationality == "American") {
      response = UnitedStatesFlag;
    }
    if (nationality == "Brazilian") {
      response = BrazilianFlag;
    }
    if (nationality == "Costa Rican") {
      response = CostaRicaFlag;
    }
    if (nationality == "Uruguayan") {
      response = UruguayanFlag;
    }
    if (nationality == "Colombian") {
      response = ColombianFlag;
    }
    if (nationality == "Spanish") {
      response = SpanishFlag;
    }
    if (nationality == "Argentine") {
      response = ArgentinaFlag;
    }
    if (nationality == "Italian") {
      response = ItalianFlag;
    }
    if (nationality == "Montenegrin") {
      response = MontenegroFlag;
    }

    return response;
  }

  useEffect(() => {
    // Player information
    axios.get(`https://stats.bravos-fcjuarez.com/players/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        setPlayerInformation(response.data);
      })
      .catch(err => {
        console.error(err);
      });

    // Player contract information
    axios.get(`https://stats.bravos-fcjuarez.com/general-information/${id}/contract-information/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        setPlayerContractInformation(response.data);
      })
      .catch(err => {
        console.log(err);
      })
  }, [id]);

  function calculateAge(birthDate) {
    if (!birthDate) return "Date not available";

    const [year, month, day] = birthDate.split('-').map(Number);
    const dateOfBirth = new Date(year, month - 1, day);
    const today = new Date();

    dateOfBirth.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    let age = today.getFullYear() - dateOfBirth.getFullYear();
    const birthMonth = dateOfBirth.getMonth();
    const birthDay = dateOfBirth.getDate();

    if (today.getMonth() < birthMonth || (today.getMonth() === birthMonth && today.getDate() < birthDay)) {
      age--;
    }

    return age;
  }

  function getContractDuration(mongo_contract_string) {
    const regex = /\[(.*?)\]/;
    const match = mongo_contract_string.match(regex);

    if (match) {
      return convertToUSDate(match[1])
    } else {
      return "Undefined.";
    }
  }

  function convertToUSDate(dateString) {
    const monthMap = {
      enero: '01',
      febrero: '02',
      marzo: '03',
      abril: '04',
      mayo: '05',
      junio: '06',
      julio: '07',
      agosto: '08',
      septiembre: '09',
      octubre: '10',
      noviembre: '11',
      diciembre: '12'
    };

    const parts = dateString.split(' ');

    const day = parts[0];
    const monthName = parts[2];
    const year = parts[4];

    const month = monthMap[monthName.toLowerCase()];

    const dayFormatted = day.padStart(2, '0');
    const monthFormatted = month.padStart(2, '0');

    const formattedDate = `${monthFormatted}-${dayFormatted}-${year}`;

    return formattedDate;
  }


  function translatePosition(position) {

    const positions = {
      "Portero": "Goalkeeper",
      "Defensa Central": "Center back",
      "Defensa Lateral": "Fullback",
      "Medio Defensivo": "Defensive Midfielder",
      "Medio Ofensivo": "Attacking Midfielder",
      "Extremo": "Winger",
      "Delantero": "Forward"
    }

    return positions[position]
  }

  if (!playerInformation) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}>
        <img style={{ maxWidth: '15vh', marginBottom: '20px' }} src={Logo} alt="Logo" />
        <CircularProgress style={{ color: '#21CB35' }} />
      </div>
    );
  }

  return (
    <div>
      <div className="player-name">
        <h1>{playerInformation.player_name}</h1>
      </div>
      <section id="player-profile">
        <div className="profile-grid-item">
          <div className="photo-div">
            <img
              style={{ borderRadius: '3px' }}
              src={playerInformation.player_photo == 'Sin Fotografia' ? NoProfilePicture : playerInformation.player_photo}
              alt={`Photo of ${playerContractInformation.player_name}`}
            />
          </div>
        </div>
        <div className="profile-grid-item">
          <section id="player-data" >
            <div className="player-data-item">
              <b>Age: </b> {calculateAge(playerInformation.date_of_birth)}
            </div>
            <div className="player-data-item">
              <b>Position: </b> {translatePosition(playerInformation.position)}
            </div>
            <div className="player-data-item">
              <style jsx>{`
      .player-data-item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
      }

      .player-data-item img {
        margin-left: 4px;
        margin-right: 4px;
        width: 25px;
      }
    `}</style>
              <b>Passport: </b>
              {playerInformation.nationality == null ? (
                "Unknown"
              ) : (
                playerInformation.nationality.includes("|") ? (
                  playerInformation.nationality.split("|").map((nationality, index, array) => (
                    <React.Fragment key={index}>
                      {nationality}
                      {index !== array.length - 1 ? (
                        <>
                          <img style={{ width: "20px" }} src={setFlag(nationality)} alt={`Flag of ${nationality}`} />
                          {" / "}
                        </>
                      ) : (
                        <img style={{ width: "20px" }} src={setFlag(nationality)} alt={`Flag of ${nationality}`} />
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <>
                    {playerInformation.nationality}
                    <img src={setFlag(playerInformation.nationality)} alt={`Flag of ${playerInformation.nationality}`} />
                  </>
                )
              )}
            </div>



            <div className="player-data-item">
              <b>Contract Expiration: </b> {getContractDuration(playerContractInformation.contract_duration)}
            </div>
            <div className="player-data-item">
              <b>Transfer Status: </b> {playerContractInformation.purchase_or_loan}
            </div>
            <div className="player-data-item">
              <b>Registration Type: </b> {playerContractInformation.tactical_system}
            </div>

            <div className="player-data-item">
              <b>Total Salary: </b>
            </div>
            <div className="player-data-item">
              <b>Player Agent: </b> {playerContractInformation.agent}
            </div>
            <div className="player-data-item">
              <b>Intermediary: </b>
              {
                playerContractInformation.beneficiary_intermediary.length > 0 ? (
                  playerContractInformation.beneficiary_intermediary.map((intermediary, index) => (
                    <div style={{ background: '#fff' }} key={index}>{intermediary.item}</div>
                  ))
                ) : "N/A"
              }
            </div>
          </section>
        </div>
      </section>
      <section id="other-details">
        <div className="other-container">
          <div className="other-item">
            <div className="information-title">
              <b>Bonus signature and or purchase:</b>
            </div>
            {playerContractInformation.bonus_signature_and_or_purchase.map((data, index) => (
              <div className="information" key={index}>
                {data.item}
              </div>
            ))}
          </div>

          <div className="other-item">
            <div className="information-title">
              <b>Intermediation:</b>
            </div>
            {playerContractInformation.intermediation.map((data, index) => (
              <div className="information" key={index}>
                {data.item}
              </div>
            ))}
          </div>
          <div className="other-item">
            <div className="information-title">
              <b>Beneficiary / Intermediary:</b>
            </div>
            {playerContractInformation.beneficiary_intermediary.map((data, index) => (
              <div className="information" key={index}>
                {data.item}
              </div>
            ))}
          </div>
          <div className="other-item">
            <div className="information-title">
              <b>Total payment:</b>
            </div>
            {playerContractInformation.total_payroll.map((data, index) => (
              <div className="information" key={index}>
                {data.description}
              </div>
            ))}
          </div>
          <div className="other-item">
            <div className="information-title">
              <b>Monthly payment:</b>
            </div>
            {playerContractInformation.montly_payroll.map((data, index) => (
              <div className="information" key={index}>
                {data.description}
              </div>
            ))}
          </div>
          <div className="other-item">
            <div className="information-title">
              <b>Monthly payments:</b>
            </div>
            {playerContractInformation.monthly_payments.map((data, index) => (
              <div className="information" key={index}>
                {data.description.split(" . ")[1]}
              </div>
            ))}
          </div>
          <div className="other-item">
            <div className="information-title">
              <b>Additional Agreements:</b>
            </div>
            <div style={{ marginTop: '2vh', marginLeft: '2vh' }}>
              <b>Bonos deportivos:</b>
            </div>
            {playerContractInformation.additional_agreements["bonos_deportivos"].map((data, index) => (
              <div className="information" key={index}>
                {data.bonus}
              </div>
            ))}
            <div style={{ marginTop: '2vh', marginLeft: '2vh' }}>
              <b>
                Bonos adicionales:
              </b>
            </div>
            {playerContractInformation.additional_agreements["bonos_adicionales"].map((data, index) => (
              <div className="information" key={index}>
                {data.bonus}
              </div>
            ))}
            <div style={{ marginTop: '2vh', marginLeft: '2vh' }}>
              <b>
                Clausula de recision:
              </b>
            </div>
            {playerContractInformation.additional_agreements["clausula_de_recision"].map((data, index) => (
              <div className="information" key={index}>
                {data.bonus}
              </div>
            ))}
          </div>
          <div className="other-item">
            <div className="information-title">
              <b>Observations:</b>
            </div>
            {playerContractInformation.observations.map((data, index) => (
              <div className="information" key={index}>
                {data.item}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Profile;
