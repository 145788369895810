import React, { useState, useEffect, useRef } from "react";
import Login from "../src/components/Login";
import "../src/assets/styles/styles.sass";
import Dashboard from "./components/Dashboard";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Toast from "./components/toast/Toast";
import NavBar from "../src/components/NavBar";
import Profile from "./components/Profile";

const theme = createTheme({
  palette: {
    primary: {
      main: "#21cb35",
    },
    background: {
      default: "#F5F5F5",
    },
    text: {
      primary: "#3e3e3e",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#F5F5F5',
        },
      },
    },
  },
});

const socketConfig = {
  autoConnect: true,
  reconnection: true,
  transports: ["websocket"],
};

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [roles, setRoles] = useState(localStorage.getItem("roles"));
  const [currentUser, setUser] = useState(localStorage.getItem("currentUser"));
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState(null);

  const handleCloseToast = () => {
    setToastData(null);
    setShowToast(false);
  };

  const handleShowToast = (title, message, type) => {
    setToastData({ title, message, type });
    setShowToast(true);
  };

  const handleLogin = ({ token, roles, currentUser }) => {
    localStorage.setItem("token", token);
    localStorage.setItem("roles", roles);
    localStorage.setItem("currentUser", currentUser);
    setToken(token);
    setRoles(roles);
    setUser(currentUser);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    setToken(null);
    setRoles(null);
  };

  return (
    // <Provider store={store}>
    <ThemeProvider theme={theme}>
      <div>
        {token ? (
          <NavBar></NavBar>
        ) : null}
        <Router>
          <Switch>
            <Route path="/" exact>
              {token ? (
                <div>
                  <div className="container">
                    {showToast && (
                      <Toast
                        title={toastData.title}
                        message={toastData.message}
                        type={toastData.type}
                        closeToast={handleCloseToast}
                      />
                    )}
                    <Dashboard />
                  </div>
                </div>
              ) : (
                <Login onLogin={handleLogin} />
              )}
            </Route>
            {/* <Route path="/signature">
                <SignatureWindow />
              </Route> */}
          </Switch>
          <Route path="/profile/:id">
            <Profile
              token={token} />
          </Route>
        </Router>
      </div>
    </ThemeProvider>
    // </Provider>
  );
};

export default App;
