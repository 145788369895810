import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: '#F5F5F5',
}));

const handleLogOut = () => {
  localStorage.removeItem('token');
  window.location.reload();
}

function NavBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: 'black' }}>
        <Toolbar>
          <CustomIconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </CustomIconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

          </Typography>
          <Button onClick={handleLogOut} color="inherit">Sign Out</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NavBar;