import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import Logo from "../assets/images/logo.png";
import NoProfilePicture from "../assets/images/NoProfilePicture.svg";

function Dashboard() {
  const [team, setTeam] = useState('first_team');
  const [tournament, setTournament] = useState('2024_clausura');
  const token = localStorage.getItem('token');

  const [players, setPlayers] = useState(null);

  useEffect(() => {
    axios.get('https://stats.bravos-fcjuarez.com/players/get_players/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setPlayers(response.data.players)
      })
      .catch(err => {
        console.error(err);
      })
  }, [token]);


  if (!players) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <img style={{ maxWidth: '15vh', marginBottom: '20px' }} src={Logo} alt="Logo" />
        <CircularProgress style={{ color: '#21CB35' }} />
      </div>
    );
  }

  return (
    <Container fixed className="container">
      <div style={{ textAlign: 'center' }}>
        <h1>General Statistics</h1>
      </div>

      <div className="flex">
        <div className="flex-item">
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel>Team</InputLabel>
            <Select
              value={team}
              onChange={(e) => setTeam(e.target.value)}
              name="team"
              label="Team"
            >
              <MenuItem key={1} value={'first_team'}>
                First Team
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="flex-item">
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel>Tournament</InputLabel>
            <Select
              value={tournament}
              onChange={(e) => setTournament(e.target.value)}
              name="tournament"
              label="Tournament"
            >
              <MenuItem key={2} value={'2024_clausura'}>
                2024 Clausura
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="grid-container">
        {players.map((player) => (
          <div key={player.id} className="grid-item" style={{background: "#e3e6e7"}}>
            <div style={{ marginTop: 'auto'}}>
              <Link to={`/profile/${player.id}`}>
                <img
                  className="player-photo"
                  src={player.photo_url === "Sin Fotografia" ? NoProfilePicture : player.photo_url}
                  alt={`Photo of ${player.player_name}`}
                />
              </Link>
            </div>
            <div style={{ fontSize: '1em', background: "#fff", height: '6vh', paddingTop: '1vh' }}>
              <b>{player.player_name}</b>
            </div>
          </div>
        ))}
      </div>

    </Container>
  )
}

export default Dashboard;
